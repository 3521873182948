function  smoothScroll(target, duration) {
    var target = document.querySelector(target);
    var targetPosition = target.getBoundingClientRect().top;
    var startPosition = window.pageYOffset;
    var startTime = null;
    
    function animation(currentTime) {
        if (startTime === null) {
            startTime = currentTime;
        }
        var timeElapsed = currentTime - startTime;
        var run = ease(timeElapsed, startPosition, targetPosition, duration);
        window.scrollTo(0, run);

        if(timeElapsed < duration){
            requestAnimationFrame(animation);
        }
    }

    function ease(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };

    requestAnimationFrame(animation);
}


const scrollSelectors = document.querySelectorAll("[id$='Select']")
scrollSelectors.forEach((scrollSelector) => {
    scrollSelector.addEventListener("click", () => { smoothScroll(`#${scrollSelector.id}-section`, 1000)})
})

const navLinks = document.querySelector('.nav-links');
const menuIcon = document.querySelector('#menu-icon');
menuIcon.addEventListener('click', function() {
    navLinks.classList.toggle('show-links')
})

const menuIconTop = document.querySelector("#menu-icon-top");
const menuIconMiddle = document.querySelector("#menu-icon-middle");
const menuIconBottom = document.querySelector("#menu-icon-bottom");
menuIcon.addEventListener("click", () => menuIconTop.classList.toggle("close-x-top"));
menuIcon.addEventListener("click", () => menuIconMiddle.classList.toggle("close-x-middle"));
menuIcon.addEventListener("click", () => menuIconBottom.classList.toggle("close-x-bottom"));

/* SCROLLTRIGGER */

gsap.registerPlugin(ScrollTrigger);

gsap.fromTo(".skill", {autoAlpha: 0, scale: 0}, {
    scrollTrigger: {
        trigger: ".skill",
        toggleActions: "play none none none",

    },
    duration: 1.2,
    autoAlpha: 1,
    scale: 1,
    ease: "power3.out"
})

gsap.fromTo(".footer-name", {y: 30, opacity: 0}, {
    y: 0,
    opacity: 1,
    scrollTrigger: {
        trigger: ".footer",
    }
})

gsap.fromTo(".gregg-secondary-img", {opacity: 0, filter: "brightness(200%)",}, {
    opacity: 1,
    filter: "brightness(100%)",
    duration: 3,
    ease: "back",
    scrollTrigger: {
        trigger: ".gregg-secondary-img"
    }
})

const projectCardBoxesOdd = gsap.utils.toArray(".odd");
projectCardBoxesOdd.forEach((projectCardBox) => {
    gsap.fromTo(projectCardBox, {opacity: 0, x: -50}, {
    duration: 1,
    opacity: 1.5,
    x: 0,
    ease: "back",
    scrollTrigger: {
        trigger: projectCardBox,

    }
    })
})

const projectCardBoxesEven = gsap.utils.toArray(".even");
projectCardBoxesEven.forEach((projectCardBox) => {
    gsap.fromTo(projectCardBox, {opacity: 0, x: 50}, {
    duration: 2,
    opacity: 1,
    x: 0,
    ease: "back",
    scrollTrigger: {
        trigger: projectCardBox,

    }
    })
})

gsap.fromTo("#envelope", {opacity: 0}, {
    opacity: 1,
    duration: 3,
    scrollTrigger: {
        trigger: ".footer",
    }
})

gsap.fromTo(".chevron-down", {autoAlpha: 0.5},{
    autoAlpha: 0,
    y: 35,
    duration: 0.5,
    scrollTrigger: {
        trigger: ".about-text",
        start: "top 30%",
        end: "bottom 50%",
        toggleActions: "restart none reset none"
    }
})

// const fas = gsap.utils.toArray(".fa");
//     gsap.fromTo(fas, {opacity: 0}, {
//         opacity: 1,
//         stagger: 0.3,
//         duration: 1.5,
//         ease: "elastic",
//         scrollTrigger: {
//             trigger: ".social",
//             toggleActions: "restart none reset reset"
//         }
// })


